import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404 - Not Found" />
    <section className="container" style={{ marginTop: '150px', textAlign: 'center', }}>
      <h1>404</h1>
    <h2 style={{ lineHeight: '60px' }}>
    So I had this whole cool idea about how I was going to make this awesome 404 page right? <br />
     But this came out instead
    </h2>   </section>
  </Layout>
)

export default NotFoundPage
